
.loading {
  display: none;
}


.loadingUl {
    list-style: none;
    display: flex !important;
    justify-content: center;
}
.loadingUl li{
    width: 30px !important;
    height: 30px !important;
    margin: 2px !important;
    background-color: transparent;
    animation: loading 0.8s infinite;
    list-style: none;
    display: flex !important;
    justify-content: center;
  
  }
  
  .loading  ul li:nth-child(5){
    opacity: 0;
  }

  .loading  ul li:nth-child(1){
    animation-delay: 0.1s;
  }
  .loading  ul li:nth-child(2){
    animation-delay: 0.2s;
  }
.loading  ul li:nth-child(3){
    animation-delay: 0.3s;
  }
.loading  ul li:nth-child(6){
    animation-delay: 0.4s;
  }
.loading  ul li:nth-child(9){
    animation-delay: 0.5s;
  }
.loading  ul li:nth-child(8){
    animation-delay: 0.6s;
  }
.loading  ul li:nth-child(7){
    animation-delay: 0.7s;
  }
.loading  ul li:nth-child(4){
    animation-delay: 0.8s;
  }
  
  @keyframes loading{
    1%{
      background-color: #0086b3;
    }
  }