.devis-form {
  /* display: flex; */
  justify-content: space-evenly;
  padding: 14px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; */
  background: white;
  background-repeat: no-repeat;
  background-size: cover;
  /* opacity: 0.6; */
  /* width: 80%; */
  /* margin: 0 auto; */
  height: 93vh;
  overflow-y: auto;
  position: relative;
  overflow-x: hidden;
  justify-content: center;
  /* text-align: center; */
}

/* #regime_conjoint {
  width: 50% !important;
} */

.row {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  justify-content: center;
  row-gap: 0;
  column-gap: 30px;
}

.items {
  display: flex;
  flex-direction: column;
  text-align: left !important;
  width: 80%;
}


.informationsRow  .formControlCSS {
  max-width: 315px;
  min-width: 300px !important;
  width: 170px !important;
}

.label-input {
  margin-bottom: 0 !important;
  display: flex;
  font-family: "Open Sans", sans-serif;
  color: #0d4aa5;
  font-weight: 700;
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 0.85rem !important;
}
.choice {
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
}

.button-step {
  padding: 5px;
  border-bottom: 1px solid #ccc;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#envoiDevis .main-form-title {
  margin-top: 0 !important;
  margin-bottom: 30px;
  
}
.form-subtitle{
  margin-bottom: 10px !important;
}

.main-form-title {
  top: -59px;
  left: 10px;
  text-align: left;
  position: initial;
  margin-top: 40px;
  margin-bottom: 40px;
}

.main-form-title::before {
  content: "";
  display: block;
  width: 2px;
  height: 20px !important;
  background-color: #3484c2;
  position: absolute;
  /* left: 0px; */
}

.button-step span {
  display: block;
  height: 12px;
  width: 12px;
  margin: 5px;
  border-radius: 50%;
  background: #c6e6ff;
}

.button-step span.is-active {
  background: rgba(0, 0, 0, 0.4);
  background: #3484c2;
}

.input-form {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  font-family: "Open Sans", sans-serif;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 25px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12.5px 14px !important;
}


.input-form-error {
  border: 1px solid #DC4F4F !important;
  /* background: url(../public/exclamation.png) no-repeat scroll 7px 7px; */
  display: inline-block;
  /* padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  -webkit-appearance: none;
  appearance: none;
  font-family: "Open Sans", sans-serif;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 25px; */
}

/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: grey !important;
} */
.input-form-sucess:not(select):not(.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input):not(.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input):not(#street_type) {
  border: 1px solid #ced4da;
  /* background: url(../public/check.png) no-repeat scroll 7px 7px; */
  display: inline-block;
  /* padding: 0.375rem 0.75rem; */
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  -webkit-appearance: none;
  appearance: none;
  font-family: "Open Sans", sans-serif;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 25px;
}

.selectForm {
  background-color: transparent;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  height: 40px;
  font-size: 13px;
}

/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline { */
/* border-color: #ced4da !important; */
/* height: 49px !important;
width: 91% !important;
} */
.warning {
  visibility: hidden;
  color: #d32f2f;
  width: 100%;
  display: block;
  /* left: 100%; */
  font-size: 0.75rem;
  font-weight: 400;
  font-family: 'Noto Sans', sans-serif;
  margin-left: 10px;
}

.is-danger {
  color: #f14668;
  display: block;
}

.is-danger input {
  border: 1px solid #f14668;
}

.multiLineEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  width: 81% !important;
  height: 72% !important;
  min-width: 100% !important;
} */

.civility {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.switch {
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input:checked+.slider {
  background-color: #3484c2;
}

input:checked+.slider {
  background-color: #3484c2;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  background-color: #d1cece;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  border-radius: 50%;
  left: 3px;
  bottom: 3px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 30%);
}


.MuiFormHelperText-root{
  color: #d32f2f !important
}

.dobMUIDatePicker {
  width: 1000px;
  background-color: red;
  font-size: 50px;
}