/* body {
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    
  /* } */
   
  .bouncing-loader {
    display: flex;
    justify-content: center;
    margin: 3.5vw auto;
  }
  
  .bouncing-loader > div {
    width: 1vw;
    height: 1vw;
    margin-left: 1vw;
    border-radius: 50%;
    background-color: #1C467E;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }
  
  @keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translateY(-1vw);
    }
  }
  
  .bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
  }
  