.divInputMui {
    display: flex;
    flex-direction: column;
    /* padding-top: 9px; */
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    min-height: 34px !important;
    padding-top: 3px !important;
    padding-bottom: 5px !important;
    font-size: .8rem !important;
}

/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    max-height:200px !important;
} */