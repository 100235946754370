@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:800);


#tableComparitif {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

/*  */

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]+label {
  display: block;
  position: relative;
  font: 14px/20px 'Open Sans', Arial, sans-serif;
  color: rgb(255, 253, 253);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

input[type="checkbox"]+label:last-child {
  margin-bottom: 0;
}

input[type="checkbox"]+label:before {
  content: '';
  display: block;
  width: 17px;
  margin-left: 10px;
  height: 17px;
  border: 1px solid #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  opacity: .6;
  -webkit-transition: all .12s, border-color .08s;
  transition: all .12s, border-color .08s;
}

input[type="checkbox"]:checked+label:before {
  width: 10px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*  */
.renfort {
  margin-top: 12px;
  width: 80%;
  height: 54px;
  border: none;
  background-color: #1C467E !important;
  color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 35px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

#buttonRenfortPrestige:hover,
#buttonRenfortPremium:hover {
  color: rgb(28, 70, 126) !important;
  background-color: white !important;
  transition: ease-out .5s;
}

.plan-cost-final {
  color: rgb(255, 255, 255);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.plan-cost-final span {
  font-size: 1.1rem;
  display: inline-block;
}

.snip1214 {
  font-family: "Raleway", Arial, sans-serif;
  color: #000000;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 8% 7% auto
}

.snip1214 .comparatif {
  list-style: none;
}

.snip1214 .plan {
  margin: 0;
  position: relative;
  float: left;
  background-color: #ffffff;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 100%;
}

.snip1214 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.categoryMobile {
  display: none;
}

.snip1214 header {
  position: relative;
}

.snip1214 .plan-title {
  position: relative;
  top: 0;
  font-weight: 800;
  padding: 5px 15px;
  margin: 0 auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  display: inline-block;
  color: #ffffff;
  text-transform: uppercase;
}


#firstTitle {
  background-color: #283048;
}

#secondTitle {
  background-color: #348ac7;
}

#thirdTitle {
  background-color: #3174a7;
}

#fourTitle {
  background-color: #2e5d88;
}

#fiveTitle {
  background-color: #2b4768;
}

.snip1214 .plan-cost {
  padding: 15px 0px 12px;
  min-height: 171px;
}

.snip1214 .plan-price {
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 800;
  font-size: 1em;
  color: #34495e;
}



#cotisation {
  font-size: 13px !important;
}

.snip1214 .plan-type {
  opacity: 0.6;
}

.snip1214 .plan-features {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: outside none none;
  font-size: 1em;
  display: block;
}

.snip1214 #comparatif li {
  line-height: 21px !important;
}

#garantie {
  position: absolute;
  top: 9%;
  box-shadow: none;
  text-decoration: none;
  width: 17%;
  left: 76%;
  background-image: none;
  color: rgb(40, 102, 160);
  border: medium none;
  text-transform: full-size-kana;
  font-size: 2.2vw;
}

[id='0_eco'],
[id='0_confort'],
[id='0_prestige'],
[id='0_premium'] {
  font-weight: 650;
}


[id='1_eco'],
[id='1_confort'],
[id='1_prestige'],
[id='1_premium'] {
  color: #ec0f6f;
  font-weight: 650;
}

.featured [id='1_eco'],
.featured [id='1_confort'],
.featured [id='1_prestige'],
.featured [id='1_premium'] {
  color: white !important;
}

ul {
  list-style: none !important;
}

#tableComparitif li,
#mobileVersionTarif li {
  border-top: 1px solid #d2d7e2 !important;
  border-bottom: 1px solid #d2d7e2;
  padding: 8px 0% !important;
  text-align: center !important;
  vertical-align: middle !important;
  line-height: 42px;
  display: flex;
  justify-content: center !important;
  font-size: 14px;
  flex-direction: column;
}

/* .snip1214 .plan-features li:nth-child(even) {
  background: rgba(0, 0, 0, 0.08);
} */

.snip1214 .plan-features i {
  margin-right: 8px;
  opacity: 0.4;
}

.snip1214 .plan-select {
  border-top: 1px solid #d2d7e2;
  padding: 10px 10px 0;
  background-color: #18abb5;
  color: #ffffff;
  text-decoration: none;
  padding: 0.5em 1em;
  -webkit-transform: translateY(39%);
  transform: translateY(39%);
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
}

.snip1214 .plan-select:hover {
  background-color: #1bbbc7;
}

.snip1214 .featured {
  background-color: #4f6374;
  color: #ffffff !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.buttonTarif {
  position: relative;
  border-style: solid;
  box-shadow: none;
  text-decoration: none;
  background-image: none;
  transition: all 300ms;
  border-radius: 6px;
  background-color: transparent;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 10px;
  font-weight: 400;
  letter-spacing: 1px;
  /* border: medium none; */
  display: block;
  font-size: 1.2vw;
  margin-bottom: 10px;
  width: fit-content;
}

.buttonBareme:hover{
  background-color: #8ba88c34;
}

.buttonGD:hover{
  background-color: #5f8aaa34;
}

.buttonExample:hover{
  background-color: #5ba9b234;
}

.snip1214 .featured .plan-title,
.snip1214 .featured .plan-price {
  color: #ffffff;
}

.snip1214 .featured .plan-features li {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.snip1214 .featured .plan-select {
  /* padding: 20px 10px 0; */
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}


.buttonPDF {
  flex-direction: row !important;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .snip1214 .plan {
    width: 50%;
  }

  .snip1214 .plan-title,
  .snip1214 .plan-select {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }


  .snip1214 .plan-select,
  .snip1214 .featured .plan-select {
    padding: 10px 10px 10px;
  }

  .snip1214 .featured {
    margin-top: 0;
  }
}

@media only screen and (max-width: 440px) {
  .snip1214 .plan {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .snip1214 {
    font-family: "Raleway", Arial, sans-serif;
    color: #000000;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 6% 8%;
  }

  .buttonPDF {
    /* position: fixed !important; */
    top: -3vw;
    /* left: 29vw; */
  }

  .buttonTarif {
    position: initial !important;
    padding: 10px 10px;
  }

  .buttonPDF {
    font-size: 1.9vw;
    cursor: pointer;
  }
}

.reductionAndCotisation {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  gap: 30px;
  width: 17vh;
  left: -110px;
  top: 0;
  height: 88%;
}


.exempleRemboursement {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 190px;
  left: -200px;
  gap: 22px;
  top: 51px;
}

.arrowRight {
  fill: #3b5998;
  height: 1em;
  vertical-align: -0.2875em;
}

.reduction {
  height: 40px;
  line-height: 40px;
  display: inline-block;
}

.blocktext {
  color: black;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  padding-left: 0px;
}

/* .cotisation .blocktext, .firstYearCotisation .blocktext {
  color: #2E2E2E !important;
} */

.cotisation {
  height: 40px;
  line-height: 40px;
  display: inline-block;

}


.firstYearCotisation {
  height: 40px;
  line-height: 40px;
  display: inline-block;
}

.boxTarif {
  display: flex;
  width: 101vw;
  left: -148px;
  position: relative;
  gap: 10px;
}

/* Reduction */
#reductionMoins50 {
  display: none !important;
}

/* TODO Dynamiquement */
.reductionOptionnelle {
  height: 40px;
  line-height: 40px;
  display: block;
}

/* Renfort */

.renfortPremium {
  display: none;
}

.renfortPrestige {
  display: none;
}

.buttonTarif img {
  width: 7vw !important;
}

#beforeRenfortPrestige span,
#beforeRenfortPremium span {
  margin: 0;
  color: white;
  font-size: 18px;
}

.activeRenfortPlan {
  background-color: #15386F !important;
  color: white;
}

.buttonActiveRenfortPlan {
  color: #1C467E !important;
  background: white !important;
}

#mobileVersionTarif {
  width: 100%;
}