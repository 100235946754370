.itemsBesoin {
    display: flex;
    flex-direction: column;
    text-align: left !important;
    transition: .5s;
    width: 100%;
    margin-top: 10px;
    min-width: 81vw;
  }

.label-inputBesoin {
    margin-bottom: 0 !important;
    display: inline-block;
    width: 290px;
    font-family: 'Open Sans', sans-serif;
    color: #335e97;
    text-align: left;
    font-size: 0.875rem;
  }

  #famille .css-b62m3t-container {
    width: 300px !important;
}
#famille .formControlCSS {
  max-width: 315px;
  min-width: 240px !important;
  width: 170px !important;
}

#familleRow {
  transition: 0.5s;
  grid-template-columns: repeat(1, 1fr) !important;
}

#familleRow .choice {
  flex-direction: row !important;
  justify-content: left !important;
}
#famille #num_insured_children {
  max-width: 41% !important;
}